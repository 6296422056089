import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  id: null,
  name: null,
  msisdn: null,
  description: null,
  previousMessageAt: null,
  autoProvisioningEnabled: false,
  properties: null,
  uptimeQueryHash: null,
  uptime: [],
};

export default function gatewayReducer(state = initialState, action) {

  switch (action.type) {
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_GATEWAY) {
        return {...initialState, id: action.payload ? action.payload.id : null };
      }

      if (action.fetchType === types.GET_GATEWAY_UPTIME) {
        return {...state, uptime: [], uptimeQueryHash: `${action.metadata.gatewayId}-${action.metadata.span}-${action.metadata.date}` };
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_GATEWAY) {
        return {
          ...state,
          id: action.payload.id, 
          name: action.payload.name, 
          msisdn: action.payload.msisdn ?? "", 
          description: action.payload.description ?? "",
          autoProvisioningEnabled: get(action.payload, "hono.autoProvisioningEnabled", false),
          properties: action.payload.properties,
          previousMessageAt: action.payload.previousMessageAt
        };
      }

      if (action.fetchType === types.GET_GATEWAY_UPTIME) {
        if (state.uptimeQueryHash === `${action.metadata.gatewayId}-${action.metadata.span}-${action.metadata.date}`) {
          return {...state, uptime: action.payload.samplesPerDay };
        }
      }

      if (action.fetchType === types.DELETE_GATEWAY) {
        toast.success(`Gateway ${state.name} deleted`);
        return initialState;
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_GATEWAY) {
        toast.error(`${statusCode}: Could not get gateway`);
        return state;
      }
      
      if (action.fetchType === types.GET_GATEWAY_UPTIME) {
        toast.error(`${statusCode}: Could not get uptime data`);
        return {...state, uptime: [] };
      }

      if (action.fetchType === types.DELETE_GATEWAY) {
        toast.error(`${statusCode}: Could not delete gateway`);
        return state;
      }

      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
