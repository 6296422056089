import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { get, last, isEmpty } from "lodash";
import FileSaver from "file-saver";
import Button from "../../components/Button";
import Freetext from "../../components/Freetext";
import InputBox from "../../components/InputBox";
import TextBox from "../../components/TextBox";
import OptionFooter from "../../components/OptionFooter";
import NoAccess from "../NoAccess";
import * as gatewayActions from "../../actions/gateways";
import * as selectedActions from "../../actions/selected";
import * as locationActions from "../../actions/locations";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";
import Checkbox from "../../components/Checkbox";
import Modal from "../../components/Modal";
import ModalContainer from "../../components/ModalContainer";
import CheckboxBlock from "../../components/CheckboxBlock";
import SmallButton from "../../components/SmallButton";

class Gateway extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      newMSISDN: "",
      newDescription: "",
      newAutoProvisioningEnabled: false,
      showDeleteGatewayModal: false,
      keepSampleHistory: true,
      forceDeleteOnDeprovisionFailure: false,
      hasLoadedState: false
    };

    this.onAddLocationClick = this.onAddLocationClick.bind(this);
    this.onDeleteGatewayClicked = this.onDeleteGatewayClicked.bind(this);
    this.onDeleteGateway = this.onDeleteGateway.bind(this);
    this.onSaveGateway = this.onSaveGateway.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onMSISDNChange = this.onMSISDNChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onAutoProvisioningEnabledChange = this.onAutoProvisioningEnabledChange.bind(this);
    this.onDownloadCertificate = this.onDownloadCertificate.bind(this);
    this.onDownloadKey = this.onDownloadKey.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // Only description changes from null when gateway is loaded
    if (!isEmpty(nextProps.gateway.id) && nextProps.gateway.id === prevState.id && nextProps.gateway.description !== null) {
      return {
        hasLoadedState: true,
        newMSISDN: get(nextProps.gateway, "msisdn", ""),
        newDescription: get(nextProps.gateway, "description", ""),
        newAutoProvisioningEnabled: get(nextProps.gateway, "autoProvisioningEnabled", false)
      };
    }

    return null;
  }

  onAddLocationClick() {
    const locationId = get(this.props.currentLocation, "id", null);
    if (locationId) {
      this.props.selectGateway(this.props.gateway, this.props.history.push, locationId);
    }
    else {
      this.props.selectGateway(this.props.gateway, this.props.history.push);
    }
  }

  onDownloadCertificate() {
    const certificate = get(this.props.gateway, "properties.hono.clientCertificate.cert", null);
    if (certificate) {
      const re = /[\s\S]*-----BEGIN CERTIFICATE-----([\s\S]*)-----END CERTIFICATE-----[\s\S]*/gi;
      const data = certificate.replace(re, '$1');
      const blob = new Blob([Buffer.from(data, "base64")], { type: "application/octet-stream" });
      FileSaver.saveAs(blob, `${this.props.gateway.id}-certificate.der`);
    }
  }

  onDownloadKey() {
    const key = get(this.props.gateway, "properties.hono.clientCertificate.key", null);
    if (key) {
      const re = /[\s\S]*-----BEGIN RSA PRIVATE KEY-----([\s\S]*)-----END RSA PRIVATE KEY-----[\s\S]*/gi;
      const data = key.replace(re, '$1');
      const blob = new Blob([Buffer.from(data, "base64")], { type: "application/octet-stream" });
      FileSaver.saveAs(blob, `${this.props.gateway.id}-certificate.key`);
    }
  }

  onMSISDNChange(event) {
    this.setState({ newMSISDN: event.target.value });
  }

  onDescriptionChange(event) {
    this.setState({ newDescription: event.target.value });
  }

  onAutoProvisioningEnabledChange(event) {
    this.setState({ newAutoProvisioningEnabled: event.target.checked });
  }

  onDeleteGatewayClicked() {
    this.setState({ showDeleteGatewayModal: true });
  }

  onDeleteGateway() {
    const options = {
      gatewayId: this.props.gateway.id,
      keepSampleHistory: this.state.keepSampleHistory,
      forceDeleteOnDeprovisionFailure: this.state.forceDeleteOnDeprovisionFailure,
      redirect: this.props.history.push
    };

    this.props.deleteGateway(options);
  }

  onSaveGateway() {
    const geometry = get(this.props.createdFeature, "geometry", {});
    const locationId = get(this.props.gateway, "properties.locationIds[0]", null);

    var body = {};

    if (geometry.type === "Point") {
      body.geoJsonFeature = this.props.createdFeature;
    }

    if (this.state.newMSISDN !== get(this.props.gateway, "msisdn", "")) {
      body.msisdn = this.state.newMSISDN;
    }

    if (this.state.newDescription !== get(this.props.gateway, "description", "")) {
      body.description = this.state.newDescription;
    }

    if (!isEmpty(this.props.gateway.properties.hono)) {
      if (this.state.newAutoProvisioningEnabled !== get(this.props.gateway, "autoProvisioningEnabled", false)) {
        body.autoProvisioningEnabled = this.state.newAutoProvisioningEnabled;
      }
    }

    this.props.updateGateway(this.props.gateway.id, body, locationId);
    this.setState({ showNameInput: false });
  }

  onCancel() {
    // Clear and reload map
    this.props.clearSelection();

    const locationId = get(this.props.gateway, "properties.locationIds[0]", null);
    if (locationId) {
      this.props.getFloorMap(locationId);
    }

    // Clear input fields
    this.setState({
      searchText: "",
      newMSISDN: get(this.props.gateway, "msisdn", ""),
      newDescription: get(this.props.gateway, "description", ""),
      newAutoProvisioningEnabled: get(this.props.gateway, "autoProvisioningEnabled", false)
    });
  }

  render() {
    const canEdit = this.props.auth.hasSupportRole;
    const canDelete = this.props.auth.hasITAdminRole;
    const canForceDelete = this.props.auth.hasITSupportRole;

    const canViewGateway = this.props.auth.hasAdminRole;
    if (!canViewGateway) {
      return <NoAccess />;
    }

    if (this.props.gateway.properties === null) {
      return null;
    }

    // Location relation links
    const locationArray = get(this.props.gateway, `properties.locationBreadcrumbs`, []);
    const locations = locationArray.map((breadcrumb, index) => {

      const location = last(breadcrumb);
      const link = location ? `/companies/${this.props.match.params.companyId}/locations/${location._id}/gateways` : `/companies/${this.props.match.params.companyId}/locations/root/locations`;

      return (
        <div className={style.buttonContainer} key={`${index}-button`}>
          <Hidden xs sm md>
            <div className={style.locationButton}>
              <Button text={breadcrumb.map(subcrumb => subcrumb.name).join(", ")} color="white" link={link} />
            </div>
          </Hidden>
          <Visible xs sm md>
            <div className={style.mobileLocationButton}>
              <Button text={breadcrumb.map(subcrumb => subcrumb.name).join(", ")} color="white" link={link} />
            </div>
          </Visible>
        </div>
      );
    });

    if (isEmpty(locationArray) && canEdit) {
      locations.push((
        <div className={style.buttonContainer} key={`add-button`}>
          <Hidden xs sm md>
            <div className={style.locationButton}>
              <Button text="Connect to a location" onClick={this.onAddLocationClick} />
            </div>
          </Hidden>
          <Visible xs sm md>
            <div className={style.mobileLocationButton}>
              <Button text="Connect to a location" onClick={this.onAddLocationClick} />
            </div>
          </Visible>
        </div>
      ));
    }

    // Options for saving
    const options = [];
    if (!isEmpty(this.props.createdFeature) ||
      this.state.newMSISDN !== this.props.gateway.msisdn ||
      this.state.newDescription !== this.props.gateway.description ||
      this.state.newAutoProvisioningEnabled !== get(this.props.gateway, "autoProvisioningEnabled", false)) {
      options.push({
        label: "Save change",
        callback: this.onSaveGateway,
      });
    }
    else if (canDelete) {
      options.push({
        label: "Delete",
        destructive: true,
        callback: this.onDeleteGatewayClicked,
      });
    }

    const username = get(this.props.gateway, "properties.hono.credentials.username", null);
    const password = get(this.props.gateway, "properties.hono.credentials.password", null);
    const certificate = get(this.props.gateway, "properties.hono.clientCertificate.cert", null);
    const key = get(this.props.gateway, "properties.hono.clientCertificate.key", null);

    const authenticationElements = (
      <>
        <Freetext header="Authentication" />
        {
          username && (
            <TextBox label="Username" value={username} disabled={true} showCopy={true} size="small" />
          )
        }
        {
          password && (
            <TextBox label="Password" value={password} disabled={true} showCopy={true} size="medium" />
          )
        }
        {
          certificate && (
            <TextBox label="Certificate" value={certificate} disabled={true} showCopy={true} size="large" onDownload={this.onDownloadCertificate} downloadText="download binary" />
          )
        }
        {
          key && (
            <TextBox label="Key" value={key} disabled={true} showCopy={true} size="medium" onDownload={this.onDownloadKey} downloadText="download binary" />
          )
        }
        <div style={{ paddingTop: "40px" }} />
      </>
    );

    const content = (
      <>
        <>
          <Row className={style.topRow}>
            <Col md={12} lg={6}>
              <InputBox label="Gateway ID" value={get(this.props.gateway, "properties.vendorInfo.sensorId", null)} required disabled />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6}>
              <InputBox label="MSISDN" value={this.state.newMSISDN} onChange={this.onMSISDNChange} />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={8}>
              <TextBox label="Description" value={this.state.newDescription} size="medium" onChange={this.onDescriptionChange} />
            </Col>
          </Row>
          {
            !isEmpty(this.props.gateway.properties.hono) && (
              <Row>
                <Col md={12} lg={8}>
                  <div style={{ marginTop: "25px" }}>
                    <Checkbox label="Use auto-provisioning" description="(enroll sensors when they connect)" isChecked={this.state.newAutoProvisioningEnabled} onClick={this.onAutoProvisioningEnabledChange} />
                  </div>
                </Col>
              </Row>
            )
          }
        </>
        {
          locations.length > 0 && (
            <div style={{ paddingTop: "40px" }}>
              <Freetext header="Locations" />
              <div className={style.locations}>
                {locations}
              </div>
            </div>
          )
        }
        <div style={{ paddingTop: "40px" }} />
        {canEdit && (username || password || certificate || key) && authenticationElements}
      </>
    );

    return (
      <div className={style.listContainer}>
        <Hidden xs sm md>
          <div className={style.scroll}>
            {content}
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.slimScroll}>
            {content}
          </div>
        </Visible>
        <OptionFooter
          cancel={(!isEmpty(this.props.createdFeature) || this.state.newMSISDN !== this.props.gateway.msisdn || this.state.newDescription !== this.props.gateway.description || this.state.newAutoProvisioningEnabled !== get(this.props.gateway, "autoProvisioningEnabled", false)) ? this.onCancel : null}
          options={options}
        />

        <ModalContainer
          isOpen={this.state.showDeleteGatewayModal}
          onClose={() => this.setState({ showDeleteGatewayModal: false })}
          fullscreen={true}
          inCenter={true}
          style={{ maxWidth: "800px" }}
        >
          <Freetext
            header="Delete gateway"
            content="When deleting a gateway, you also have to decide what to do with the sensors connected to it. You can either keep the history or delete it. By choosing to keep the history, sensor data will be preserved for later inspection. Opting to delete the sample history will permanently remove sensor data."
          />
          <Checkbox label="Keep sensor history" isChecked={this.state.keepSampleHistory} onClick={() => this.setState({ keepSampleHistory: !this.state.keepSampleHistory })} description="(recommended)" />
          {canForceDelete && <Checkbox label="Force delete on deprovision failure" isChecked={this.state.forceDeleteOnDeprovisionFailure} onClick={() => this.setState({ forceDeleteOnDeprovisionFailure: !this.state.forceDeleteOnDeprovisionFailure })} />}
          <div style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
            <SmallButton text="Cancel" color="white" onClick={() => this.setState({ showDeleteGatewayModal: false })} />
            <SmallButton text="Delete" color="red" onClick={this.onDeleteGateway} />
          </div>
        </ModalContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    gateway: state.gateway,
    currentLocation: state.location,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
    isLoading: state.loading.gateways,
    isLoadingLocation: state.loading[API.GET_LOCATION],
    isLoadingMap: state.loading[API.GET_FLOOR_MAP],
    createdFeature: state.selected.createdFeature,
    companyMap: state.auth.map,
    gatewaySensors: state.sensors.gatewaySensors,
    gatewaySensorsIsLoading: state.sensors.gatewaySensorsIsLoading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getGateway: gatewayActions.getGateway,
    selectGateway: selectedActions.selectGateway,
    updateGateway: gatewayActions.updateGateway,
    deleteGateway: gatewayActions.deleteGateway,
    getGatewaySensors: gatewayActions.getGatewaySensors,
    clearSelection: selectedActions.clearSelection,
    getFloorMap: locationActions.getFloorMap,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Gateway);