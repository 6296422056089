import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { get, isEmpty, last } from "lodash";
import FileSaver from "file-saver";
import semver from "semver";
import MapboxMap from "../Map/MapboxMap";
import Button from "../../components/Button";
import Freetext from "../../components/Freetext";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import OptionFooter from "../../components/OptionFooter";
import TextBox from "../../components/TextBox";
import InputBox from "../../components/InputBox";
import ModalContainer from "../../components/ModalContainer";
import Checkbox from "../../components/Checkbox";
import SmallButton from "../../components/SmallButton";
import * as sensorActions from "../../actions/sensors";
import * as selectedActions from "../../actions/selected";
import * as locationActions from "../../actions/locations";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";

class SensorDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      newMSISDN: "",
      newDescription: "",
      copyMacro: "Copy macro (OS v10)",
      copyMacroV11: "Copy macro (OS v11)",
      atomicSensorDraft: {},
      vortoId: "",
      showDeleteSensorModal: false,
      keepSampleHistory: true
    };
   
    this.updateData = this.updateData.bind(this);
    this.onAddLocationClick = this.onAddLocationClick.bind(this);
    this.onDeleteSensorClicked = this.onDeleteSensorClicked.bind(this);
    this.onDeleteSensor = this.onDeleteSensor.bind(this);
    this.onSaveSensor = this.onSaveSensor.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onMSISDNChange = this.onMSISDNChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onVortoVendorChange = this.onVortoVendorChange.bind(this);
    this.onVortoModelChange = this.onVortoModelChange.bind(this);
    this.onVortoVersionChange = this.onVortoVersionChange.bind(this);
    this.onEditAtomicSensorClick = this.onEditAtomicSensorClick.bind(this);
    this.onAtomicSensorNameChange = this.onAtomicSensorNameChange.bind(this);
    this.onSaveAtomicSensor = this.onSaveAtomicSensor.bind(this);
    this.onCancelAtomicSensorEdit = this.onCancelAtomicSensorEdit.bind(this);
    this.onCopyMacroClick = this.onCopyMacroClick.bind(this);
    this.onCopyMacroV11Click = this.onCopyMacroV11Click.bind(this);
    this.onDownloadCertificate = this.onDownloadCertificate.bind(this);
    this.onDownloadKey = this.onDownloadKey.bind(this);

    document.title = `BLDNG.ai - Sensor`;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.vortoIds && nextProps.sensor.id && prevState.vortoId === "") {
      const vortoId = get(nextProps.sensor, "properties.metadata.vortoId", null);

      if (vortoId) {
        const vorto = nextProps.vortoIds.find(vorto => vorto.id === vortoId);

        if (vorto) {
          return {
            vortoId: vorto.id,
            newMSISDN: get(nextProps.sensor, "msisdn", ""),
            newDescription: get(nextProps.sensor, "description", "")
          }
        }
      }
      
      return {
        vortoId: null,
        newMSISDN: get(nextProps.sensor, "msisdn", ""),
        newDescription: get(nextProps.sensor, "description", "")
      }
    }

    return null;
  }

  componentDidMount() {
    // console.log("SensorDetails.componentDidMount");
    this.updateData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("SensorDetails.componentDidUpdate");
    this.updateData();
  }

  updateData() {
    // console.log("SensorDetails.updateData");
    const canViewSensorStatus = this.props.auth.hasAdminRole;

    // Only load data if sensor change
    if (this.props.sensor.id !== this.props.match.params.id) {
      this.props.getSensor(this.props.match.params.id, canViewSensorStatus);
    }
    else {
      const locationId = get(this.props.sensor, "properties.metadata.locationIds[0]", null);
      if (!isEmpty(this.props.locationHierarchy)) {
        
        if (locationId) {
          const breadcrumbs = this.props.breadcrumbs[locationId] ?? [];

          if (locationId !== this.props.currentLocation.id && !this.props.isLoadingLocation) {
            this.props.getLocation(locationId);
          }
  
          // If breadcrumbs include a floor, load the floor map
          const floor = breadcrumbs.find(breadcrumb => breadcrumb.type === "floor");
          if (floor && floor.id !== this.props.currentLocation.floorMapId && !this.props.isLoadingMap) {
            this.props.getFloorMap(floor.id);
          }
        }
        else {
          this.props.clearLocation();
        }

      }
    }
  }

  onCopyMacroClick() {
    if (this.props.webexMacro !== "") {
      window.Clipboard.copy(this.props.webexMacro);
      this.setState({ copyMacro: "Copied (OS v10)" });
    }
    else {
      const { keystore, sensorId } = this.props.sensor.properties.metadata.vendorInfo;
      this.props.getWebexMacro(keystore, sensorId, 10);
    }
  }

  onCopyMacroV11Click() {
    if (this.props.webexMacroV11 !== "") {
      window.Clipboard.copy(this.props.webexMacroV11);
      this.setState({ copyMacroV11: "Copied (OS v11)" });
    }
    else {
      const { keystore, sensorId } = this.props.sensor.properties.metadata.vendorInfo;
      this.props.getWebexMacro(keystore, sensorId, 11);
    }
  }

  onAddLocationClick() {
    const locationId = get(this.props.currentLocation, "id", null);
    if (locationId) {
      this.props.selectSensorWithRedirect(this.props.sensor, this.props.history.push, locationId);
    }
    else {
      this.props.selectSensorWithRedirect(this.props.sensor, this.props.history.push);
    }
  }

  onDeleteSensorClicked() {
    this.setState({ showDeleteSensorModal: true });
  }

  onDeleteSensor() {
    this.props.deleteSensor(this.props.sensor.id, null, this.state.keepSampleHistory, this.props.history.push);
  }
  
  onSaveSensor() {
    const geometry = get(this.props.createdFeature, "geometry", {});
    const locationId = get(this.props.sensor, "properties.metadata.locationIds[0]", null);
    const vortoId = get(this.props.sensor, "properties.metadata.vortoId", null);

    const body = {};

    if (geometry.type === "Point") {
      body.geoJsonFeature = this.props.createdFeature;
    }

    if (this.state.newMSISDN !== get(this.props, "sensor.msisdn", "")) {
      body.msisdn = this.state.newMSISDN;
    }

    if (this.state.newDescription !== get(this.props, "sensor.description", "")) {
      body.description = this.state.newDescription;
    }

    if (this.state.vortoId !== null && this.state.vortoId !== vortoId) {
      body.vortoId = this.state.vortoId;
    }

    this.props.updateSensor(this.props.sensor.id, body, locationId);
  }

  onCancel() {
    // Clear and reload map
    this.props.clearSelection();
    const locationId = get(this.props.sensor, "properties.metadata.locationIds[0]", null);
    if (locationId) {
      const breadcrumbs = this.props.breadcrumbs[locationId] ?? [];
      
      // If breadcrumbs include a floor, load the floor map
      const floor = breadcrumbs.find(breadcrumb => breadcrumb.type === "floor");
      if (floor && !this.props.isLoadingMap) {
        this.props.getFloorMap(floor.id);
      }
    }

    const vortoId = get(this.props.sensor, "properties.metadata.vortoId", null);

    this.setState({ 
      vortoId,
      newMSISDN: get(this.props.sensor, "msisdn", ""),
      newDescription: get(this.props.sensor, "description", "")
    });
  }

  onDownloadCertificate(){
    const certificate = get(this.props.sensor, "properties.metadata.hono.clientCertificate.cert", null);
    if(certificate){
      const re = /[\s\S]*-----BEGIN CERTIFICATE-----([\s\S]*)-----END CERTIFICATE-----[\s\S]*/gi;
      const data = certificate.replace(re, '$1');
      const blob = new Blob([Buffer.from(data, "base64")], { type: "application/octet-stream" });
      FileSaver.saveAs(blob,`${this.props.sensor.id}-certificate.der`);
    }
  }

  onDownloadKey(){
    const key = get(this.props.sensor, "properties.metadata.hono.clientCertificate.key", null);
    if(key){
      const re = /[\s\S]*-----BEGIN RSA PRIVATE KEY-----([\s\S]*)-----END RSA PRIVATE KEY-----[\s\S]*/gi;
      const data = key.replace(re, '$1');
      const blob = new Blob([Buffer.from(data, "base64")], { type: "application/octet-stream" });
      FileSaver.saveAs(blob,`${this.props.sensor.id}-certificate.key`);
    }
  }

  onMSISDNChange(event) {
    this.setState({ newMSISDN: event.target.value });
  }

  onDescriptionChange(event) {
    this.setState({ newDescription: event.target.value });
  }

  onVortoVendorChange(event) {
    const vortoVendor = event.target.value;
    
    // Find first vortoId with the same vendor
    const vortoVendorObject = this.props.vendors[vortoVendor];

    const models = Object.keys(vortoVendorObject);
    models.sort();
    const firstModel = models[0];

    const versions = Object.keys(vortoVendorObject[firstModel]);
    versions.sort();
    const firstVersion = versions[0];

    const vortoId = vortoVendorObject[firstModel][firstVersion];

    this.setState({ vortoId });
  }

  onVortoModelChange(event) {
    const vortoModel = event.target.value;

    const oldVorto = this.props.vortoIds.find(vorto => vorto.id === this.state.vortoId);
    
    // Find first vortoId with the same vendor
    const vortoModelObject = this.props.vendors[oldVorto.vendor][vortoModel];

    const versions = Object.keys(vortoModelObject);
    versions.sort();
    const firstVersion = versions[0];

    const vortoId = vortoModelObject[firstVersion];

    this.setState({ vortoId });
  }

  onVortoVersionChange(event) {
    const vortoVersion = event.target.value;

    const oldVorto = this.props.vortoIds.find(vorto => vorto.id === this.state.vortoId);
    
    const vortoId = this.props.vendors[oldVorto.vendor][oldVorto.model][vortoVersion];

    this.setState({ vortoId });
  }

  onEditAtomicSensorClick(id) {
    const canUpdateSensor = this.props.auth.hasSupportRole;
    const atomicSensor = this.props.sensor.atomicSensors.find(atomicSensor => atomicSensor._id === id);
    if (canUpdateSensor && atomicSensor) {
      this.setState({ atomicSensorDraft: { id, name: atomicSensor.name } });
    }
  }

  onAtomicSensorNameChange(event) {
    event.persist();
    this.setState(prevState => ({ atomicSensorDraft: { ...prevState.atomicSensorDraft, name: event.target.value }}));
  }

  onSaveAtomicSensor() {
    const { id, name } = this.state.atomicSensorDraft;
    this.props.updateAtomicSensor(this.props.sensorId, id, { displayName: name });
    this.setState({ atomicSensorDraft: null });
  }
  
  onCancelAtomicSensorEdit() {
    this.setState({ atomicSensorDraft: null });
  }

  rowsForLocations() {
    const rows = [
      {
        header: "Name",
        accessorKey: "name"
      }
    ];

    return rows;
  }

  render() {
    const { isLoading } = this.props;

    const canViewSensorStatus = this.props.auth.hasAdminRole;
    const canUpdateSensor = this.props.auth.hasSupportRole;
    const canDeleteSensor = this.props.auth.hasITAdminRole;

    if (isLoading || this.props.sensor.id !== this.props.match.params.id || this.props.sensor.properties === null) {
      return null;
    }

    // Hono credentials
    const username = get(this.props.sensor, "properties.metadata.hono.credentials.username", null);
    const password = get(this.props.sensor, "properties.metadata.hono.credentials.password", null);
    const certificate = get(this.props.sensor, "properties.metadata.hono.clientCertificate.cert", null);
    const key = get(this.props.sensor, "properties.metadata.hono.clientCertificate.key", null);
    const authenticationElements = (
      <>
        <Freetext header="Authentication" />
        {
          username && (
            <TextBox label="Username" value={username} disabled={true} showCopy={true} size="small" />
          )
        }
        {
          password && (
            <TextBox label="Password" value={password} disabled={true} showCopy={true} size="medium" />
          )
        }
        {
          certificate && (
            <TextBox label="Certificate" value={certificate} disabled={true} showCopy={true} size="large" onDownload={this.onDownloadCertificate} downloadText="download binary" />
          )
        }
        {
          key && (
            <TextBox label="Key" value={key} disabled={true} showCopy={true} size="medium" onDownload={this.onDownloadKey} downloadText="download binary" />
          )
        }
        <div style={{ paddingTop: "40px" }} />
      </>
    );

    // Location relation links
    const locationArray = get(this.props.sensor, `properties.metadata.locationBreadcrumbs`, []);
    const locations = locationArray.map((breadcrumb) => {
      
      const location = last(breadcrumb);
      const link = location ? `/companies/${this.props.match.params.companyId}/locations/${location._id}/sensors` : `/companies/${this.props.match.params.companyId}/locations/root/locations`;
      
      return (
        <div key={`${breadcrumb._id}-button`} style={{ width: "100%" }}>
          <Hidden xs sm md>
            <div className={style.locationButton}>
              <Button text={breadcrumb.map(subcrumb => subcrumb.name).join(", ")} color="white" link={link} />
            </div>
          </Hidden>
          <Visible xs sm md>
            <div className={style.mobileLocationButton}>
              <Button text={breadcrumb.map(subcrumb => subcrumb.name).join(", ")} color="white" link={link} />
            </div>
          </Visible>
        </div>
      );
    });

    if (isEmpty(locationArray) && canUpdateSensor) {
      locations.push((
        <div key="add-location-button" style={{ width: "100%" }}>
          <Hidden xs sm md>
            <div className={style.locationButton}>
              <Button text="Connect to a location" onClick={this.onAddLocationClick} />
            </div>
          </Hidden>
          <Visible xs sm md>
            <div className={style.mobileLocationButton}>
              <Button text="Connect to a location" onClick={this.onAddLocationClick} />
            </div>
          </Visible>
        </div>
      ));
    }

    let mapElement;
    // If sensor has a geoJSONFeature - show movable point
    // else show map with draw_point to let user place the sensor in the map
    if (this.props.currentLocation.floorMap) {
      const geoJsonFeature = get(this.props.sensor, `properties.metadata.geoJsonFeature`, null);
      if (!isEmpty(geoJsonFeature)) {
        mapElement = (
          <div className={style.mapPart}>
            <MapboxMap
              mapId="edit-sensor-map-container"
              id={this.state.id}
              company={this.props.selectedCompany}
              location={this.props.currentLocation}
              map={this.props.currentLocation.floorMap}
              drawMode="simple_select"
              geoJsonFeature={geoJsonFeature}
              showSensors
              showGateways
              drawSensor
              showMap
            />
            <div className={style.mapHelp}>
              <span>Sensors are </span>
              <span style={{ color: "#0080FF" }}>blue</span>
              <span>. Gateways are </span>
              <span style={{ color: "#82612F" }}>brown</span>
              <span>.</span>
            </div>
          </div>
        );
      }
      else {
        mapElement = (
          <div className={style.mapPart}>
            <MapboxMap 
              mapId="edit-sensor-map-container"
              id={this.state.id}
              company={this.props.selectedCompany}
              location={this.props.currentLocation}
              map={this.props.currentLocation.floorMap}
              drawMode="draw_point"
              showSensors
              showGateways
              drawSensor
              showMap
            />
            <div className={style.mapHelp}>
              <span>Sensors are </span>
              <span style={{ color: "#0080FF" }}>blue</span>
              <span>. Gateways are </span>
              <span style={{ color: "#82612F" }}>brown</span>
              <span>.</span>
            </div>
          </div>
        );
      }
    }
    else {
      // If no floor map is available, show a message
      mapElement = (
        <div className={style.mapPart}>
          <div className={style.mapHelpCenter}>
            No floor map available
          </div>
        </div>
      );
    }

    // Options for saving
    const options = [];
    const vortoId = get(this.props.sensor, "properties.metadata.vortoId", null);
    if (!isEmpty(this.props.createdFeature) || 
        this.state.newMSISDN !== this.props.sensor.msisdn || 
        this.state.newDescription !== this.props.sensor.description || 
        (this.state.vortoId !== null && this.state.vortoId !== vortoId))
    {
      options.push({ 
        label: "Save change", 
        callback: this.onSaveSensor, 
      });
    }
    else {
      if (canUpdateSensor && get(this.props.sensor, "properties.metadata.vendorInfo.keystore", null)) {
        // Copy macro
        options.push({ 
          label: this.props.webexMacro !== "" ? this.state.copyMacro : "Generate macro (OS v10)",
          callback: this.onCopyMacroClick
        });
        options.push({ 
          label: this.props.webexMacroV11 !== "" ? this.state.copyMacroV11 : "Generate macro (OS v11)",
          callback: this.onCopyMacroV11Click
        });
      }

      if (canDeleteSensor) {
        options.push({ 
          label: "Delete", 
          destructive: true,
          callback: this.onDeleteSensorClicked
        });
      }
    }

    // Gateway link
    let gateway = null;
    if (get(this.props.sensor, "properties.metadata.gateway", null)) {
      const gatewayId = get(this.props.sensor, "properties.metadata.gateway.id", null);
      const gatewayLink = `/companies/${this.props.match.params.companyId}/gateways/${gatewayId}`;
      gateway = (
        <>
          <Freetext header="Gateway" />
          <Hidden xs sm md>
            <div className={style.locationButton}>
              <Button text={this.props.sensor.properties.metadata.gateway.name ?? "Unnamed gateway"} color="white" link={gatewayLink} />
            </div>
          </Hidden>
          <Visible xs sm md>
            <div className={style.mobileLocationButton}>
              <Button text={this.props.sensor.properties.metadata.gateway.name ?? "Unnamed gateway"} color="white" link={gatewayLink} />
            </div>
          </Visible>
          <div style={{ paddingTop: "40px"}} />
        </>
      );
    }
    
    // Get selectedVortoVendor, selectedVortoModel and selectedVortoVersion from vortoIds.find() and match on this.state.vortoId
    const vorto = this.props.vortoIds.find(vorto => vorto.id === this.state.vortoId);
    const selectedVortoVendor = vorto ? vorto.vendor : null;
    const selectedVortoModel = vorto ? vorto.model : null;
    const selectedVortoVersion = vorto ? vorto.version : null;

    // Get list of vendors from vortoIds
    const vendorList = Object.keys(this.props.vendors);
    vendorList.sort((a, b) => a.localeCompare(b));
    const vendorOptions = vendorList.map(vendorName => ({ id: vendorName, name: vendorName }));

    // Get list of models for options
    let modelOptions = [];
    if (selectedVortoVendor) {
      const modelList = Object.keys(this.props.vendors[selectedVortoVendor]);
      modelList.sort((a, b) => a.localeCompare(b));
      modelOptions = modelList.map(modelName => ({ id: modelName, name: modelName }));
    }
    
    // Get list of versions for options
    let versionOptions = [];
    if (selectedVortoVendor && selectedVortoModel) {
      const versionList = Object.keys(this.props.vendors[selectedVortoVendor][selectedVortoModel]);

      // Sort versionList by semver
      versionList.sort((a, b) => semver.gt(a, b) ? 1 : -1);
      versionOptions = versionList.map(versionName => ({ id: versionName, name: versionName }));

      // Only show the last version, unless the oldVortoVersion is older, then show that one and the last one
      const oldVortoId = get(this.props.sensor, "properties.metadata.vortoId", null);
      if (oldVortoId === this.state.vortoId) {
        const oldVorto = this.props.vortoIds.find(vorto => vorto.id === oldVortoId);
        const oldVortoVersion = oldVorto ? oldVorto.version : null;
        versionOptions = versionOptions.filter(version => !oldVortoVersion || version.name === oldVortoVersion || semver.gt(version.name, oldVortoVersion));
      }
      else {
        versionOptions = [versionOptions[versionOptions.length - 1]];
      }
    }

    const heading = (
      <>
        <Row className={style.topRow}>
          <Col md={12} lg={6}>
            <InputBox label="Sensor ID" value={get(this.props.sensor, "properties.metadata.vendorInfo.sensorId", null)} required disabled />
          </Col>
        </Row>
        {
          this.state.vortoId !== null && (
            <Row>
              <Col>
                <ControlledDropdownSelection
                  name="sensorVendor"
                  label="Sensor vendor"
                  value={selectedVortoVendor}
                  options={vendorOptions}
                  onChange={this.onVortoVendorChange}
                  style={{ marginRight: "20px" }}
                />
                <ControlledDropdownSelection
                  name="sensorModel"
                  label="Sensor model"
                  value={selectedVortoModel}
                  options={modelOptions}
                  onChange={this.onVortoModelChange}
                  style={{ marginRight: "20px" }}
                />
                <ControlledDropdownSelection
                  name="sensorVersion"
                  label="Model version"
                  value={selectedVortoVersion}
                  options={versionOptions}
                  onChange={this.onVortoVersionChange}
                />
              </Col>
            </Row>
          )
        }
        <Row>
          <Col md={12} lg={6}>
            <InputBox label="MSISDN" value={this.state.newMSISDN} onChange={this.onMSISDNChange} />
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={8}>
            <TextBox label="Description" value={this.state.newDescription} size="medium" onChange={this.onDescriptionChange} />
          </Col>
        </Row>
        {
          locations.length > 0 && (
            <div style={{ paddingTop: "40px"}}>
              <Freetext header="Locations" />
              <div className={style.locations}>
                { locations }
              </div>
            </div>
          )
        }
        <div style={{ paddingTop: "40px"}} />
        { gateway }
      </>
    );

    return (
      <div className={style.listContainer}>
        <div className={style.row}>
          <Hidden xs sm md>
            <div className={style.colList}>
              <div className={style.scroll}>
                { heading }
                { canUpdateSensor && (username || password || certificate || key) && authenticationElements }
              </div>
            </div>
            <div className={style.colMap}>
              { mapElement }
            </div>
          </Hidden>
          <Visible xs sm md>
            <div className={style.col}>
              <div className={style.slimScroll}>
                { heading }
              </div>
            </div>
          </Visible>
        </div>
        <OptionFooter 
          cancel={!isEmpty(this.props.createdFeature) || this.state.newMSISDN !== this.props.sensor.msisdn || this.state.newDescription !== this.props.sensor.description || (this.state.vortoId !== null && this.state.vortoId !== vortoId) ? this.onCancel : null} 
          options={options} 
        />

        <ModalContainer
          isOpen={this.state.showDeleteSensorModal}
          onClose={() => this.setState({ showDeleteSensorModal: false })}
          fullscreen={true}
          inCenter={true}
          style={{ maxWidth: "800px" }}
        >
          <Freetext 
            header="Delete sensor"
            content="You can either keep the sample history or delete it. By choosing to keep the history, sensor data will be preserved for later inspection. Opting to delete the sample history will permanently remove sensor data."
          />
          <Checkbox label="Keep sensor history" isChecked={this.state.keepSampleHistory} onClick={() => this.setState({ keepSampleHistory: !this.state.keepSampleHistory })} description="(recommended)"/>
          <div style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
            <SmallButton text="Cancel" color="white" onClick={() => this.setState({ showDeleteSensorModal: false })} />
            <SmallButton text="Delete" color="red" onClick={this.onDeleteSensor} />
          </div>
        </ModalContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sensor: state.sensor,
    currentLocation: state.location,
    locationHierarchy: state.locations.hierarchy,
    breadcrumbs: state.locations.breadcrumbs,
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isLoading: state.loading.sensor,
    createdFeature: state.selected.createdFeature,
    companyMap: state.auth.map,
    vortoIds: state.auth.vortoIds,
    vendors: state.auth.vendors,
    webexMacro: state.sensor.webexMacro,
    webexMacroV11: state.sensor.webexMacroV11,
    isLoadingMap: state.loading[API.GET_FLOOR_MAP],
    isLoadingLocation: state.loading[API.GET_LOCATION],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getSensor: sensorActions.getSensor,
    selectSensorWithRedirect: selectedActions.selectSensorWithRedirect,
    getLocation: locationActions.getLocation,
    clearLocation: locationActions.clearLocation,
    getFloorMap: locationActions.getFloorMap,
    updateSensor: sensorActions.updateSensor,
    deleteSensor: sensorActions.deleteSensor,
    clearSelection: selectedActions.clearSelection,
    getWebexMacro: sensorActions.getWebexMacro
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorDetails);