import React from "react";
import styled from "styled-components";

const ModalContainer = ({ isOpen, onClose, minimalPadding = false, fullscreen = false, inCenter = false, style, children }) => {
  if (!isOpen) return null;

  return (
    <Overlay $fullscreen={fullscreen} onMouseDown={onClose}>
      <Container $fullscreen={fullscreen} $inCenter={inCenter}>
        <ScrollBox>
          <Block onMouseDown={(e) => e.stopPropagation()} style={style}>
            <BlockContent $minimalPadding={minimalPadding}>
              {children}
            </BlockContent>
          </Block>
        </ScrollBox>
      </Container>
    </Overlay>
  );
};

export default ModalContainer;

const Overlay = styled.div`
  position: ${({ $fullscreen }) => $fullscreen ? "fixed" : "absolute"};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(216, 216, 216, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Container = styled.div`
  position: ${({ $fullscreen }) => $fullscreen ? "fixed" : "absolute"};
  top: ${({ $inCenter }) => $inCenter ? "50%" : "0"};
  left: ${({ $inCenter }) => $inCenter ? "50%" : "0"};
  bottom: 0;

  transform: ${({ $inCenter }) => $inCenter ? "translate(-50%, -50%)" : "none"};
  width: 100%;
  z-index: 3;
`;

const ScrollBox = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
`;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;

  max-width: 1100px;
  margin: 0 auto;
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  // min-height: 350px;
  padding: ${({ $minimalPadding }) => $minimalPadding ? "20px" : "20px 30px"};
  font-size: 16px;
  font-weight: 400;
  justify-content: flex-start;
`;