import axios from "axios";
import FileSaver from "file-saver";
import Cookies from "universal-cookie";
import moment from "moment";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import * as locationActions from "./locations";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getAllGateways = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_ALL_GATEWAYS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/gateway-metrics`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_ALL_GATEWAYS))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_ALL_GATEWAYS));
      }
    });
};

export const setGatewaySearch = (queryParams) => async (dispatch) => {
  dispatch({ type: types.SET_GATEWAY_SEARCH, payload: queryParams });
};

export const clearAllGateways = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_ALL_GATEWAYS });
};

export const getGateways = (id, queryParams) => async (dispatch) => {

  // If view the root object show all gateways
  if (id === "root") {
    dispatch(getAllGateways(queryParams));
    return;
  }

  dispatch(actions.requestData(types.GET_GATEWAYS, id));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locations/${id}/gateways`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_GATEWAYS, id));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_GATEWAYS));
      }
    });
};

export const moveGateways = (ids, currentLocationId) => async (dispatch) => {

  dispatch(actions.requestData(types.MOVE_GATEWAYS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all GATEWAY IDS
  axios.all(ids.map((id) => (
      axios.put(`${apiUrl}companies/${companyId}/gateways/${id}`, {
        locations: [currentLocationId]
      }, config)
    )))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.MOVE_GATEWAYS));
      dispatch(selectionActions.clearSelection());
      dispatch(locationActions.getLocation(currentLocationId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.MOVE_GATEWAYS));
      }
    });
};

export const getGateway = (id) => async (dispatch) => {
  dispatch({
    type: types.REQ_DATA,
    payload: {
      id
    },
    fetchType: types.GET_GATEWAY
  });

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/gateways/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_GATEWAY));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_GATEWAY));
      }
    });
};

export const createGateway = (type, gateway, currentLocationId, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_GATEWAY));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  let url = "";
  switch (type) {
    case "yanzi":
      url = `${apiUrl}companies/${companyId}/yanzi-gateways`;
      break;
    case "hono":
      url = `${apiUrl}companies/${companyId}/hono/gateways`;
      break;
    default:
      dispatch(actions.receiveError(null, types.CREATE_GATEWAY));
      return;
  }

  axios.post(url, gateway, await actions.getAxiosConfig())
    .then((response) => {

      dispatch(actions.receiveData(response.data, types.CREATE_GATEWAY));
      dispatch(selectionActions.clearSelection());

      if (currentLocationId && redirect && type !== "hono") {
        redirect(`/companies/${companyId}/locations/${currentLocationId}/gateways`);
      }

    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_GATEWAY));
      }
    });
};

export const updateGateway = (id, body, locationId) => async (dispatch) => {
  dispatch({
    type: types.REQ_DATA,
    payload: {
      id
    },
    fetchType: types.UPDATE_GATEWAY
  });

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/gateways/${id}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_GATEWAY));
      dispatch(selectionActions.clearSelection());
      dispatch(locationActions.getFloorMap(locationId));
      dispatch(getGateway(id));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_GATEWAY));
      }
    });
};

export const getGatewaySensors = (id, queryParams) => async (dispatch) => {
  dispatch({
    type: types.REQ_DATA,
    payload: {
      id
    },
    fetchType: types.GET_GATEWAY_SENSORS
  });

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/gateways/${id}/sensors`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_GATEWAY_SENSORS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_GATEWAY_SENSORS));
      }
    });
}

export const downloadGatewaysCSV = () => async (dispatch) => {
  dispatch(actions.requestData(types.DOWNLOAD_GATEWAY_CSV));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/csv/gateways`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(null, types.DOWNLOAD_GATEWAY_CSV));
      const blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(blob, "gateways.csv")
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DOWNLOAD_GATEWAY_CSV));
      }
    });
}; 

export const clearCreatedJob = () => async (dispatch) => {
  dispatch(actions.requestData(types.CLEAR_CREATED_JOB));
};

export const getGatewayUptime = (gatewayId, span = "day") => async (dispatch) => {
  const today = moment().startOf("day").toISOString();
  dispatch(actions.requestData(types.GET_GATEWAY_UPTIME, { gatewayId, date: today, span }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = { span };

  if (span === "day") {
    const startDate = moment().subtract(30, "days").startOf("day").toISOString();
    const endDate = moment().startOf("day").toISOString();
    queryParams.startdate = startDate;
    queryParams.enddate = endDate;
  }
  else if (span === "hour") {
    const startDate = moment().subtract(7, "days").startOf("day").toISOString();
    const endDate = moment().startOf("hour").toISOString();
    queryParams.startdate = startDate;
    queryParams.enddate = endDate;
  }
  else if (span === "quarter") {
    const startDate = moment().startOf("day").toISOString();

    // Round down to nearest quarter hour
    const roundedMinutes = Math.floor(moment().minutes() / 15) * 15;
    const endDate = moment().startOf("hour").minutes(roundedMinutes).toISOString();
    
    queryParams.startdate = startDate;
    queryParams.enddate = endDate;
  }
  
  axios.get(`${apiUrl}companies/${companyId}/gateways/${gatewayId}/samples-per-day`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_GATEWAY_UPTIME, { gatewayId, date: today, span }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_GATEWAY_UPTIME));
      }
    });
}

export const deleteGateway = ({ gatewayId, currentLocationId, keepSampleHistory = true, forceDeleteOnDeprovisionFailure = false, redirect }) => async (dispatch) => {

  dispatch(actions.requestData(types.DELETE_GATEWAY));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = { 
    keepSampleHistory, 
    forceDeleteOnDeprovisionFailure
  };

  axios.delete(`${apiUrl}companies/${companyId}/gateways/${gatewayId}`, await actions.getAxiosConfig(queryParams))
    .then((response) => {

      dispatch(actions.receiveData(response.data, types.DELETE_GATEWAY));
      dispatch(selectionActions.clearSelection());
      if (currentLocationId) {
        dispatch(getGateways(currentLocationId));
      }
      else if (redirect) {
        redirect(`/companies/${companyId}/home`);
      }

    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_GATEWAY));
      }
    });
};